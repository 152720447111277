body {
  background-color: #232323;
  font-family: 'DM Sans'
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gradient-custom {
  background: #171717;
  /*background: -webkit-linear-gradient(to bottom right, rgba(252, 203, 144, 1), rgba(213, 126, 235, 1));
  background: linear-gradient(to bottom right, rgba(252, 203, 144, 1), rgba(213, 126, 235, 1)); */
  height: 100vh;
  }


.card {
  --mdb-card-spacer-y: 1.5rem;
  --mdb-card-spacer-x: 1.5rem;
  --mdb-card-title-spacer-y: 0.5rem;
  --mdb-card-border-width: 1px;
  --mdb-card-border-color: var(--mdb-border-color-translucent);
  --mdb-card-border-radius: 0.5rem;
  --mdb-card-box-shadow: 0 2px 15px -3px rgba(0,0,0,0.07), 0 10px 20px -2px rgba(0,0,0,0.04);
  --mdb-card-inner-border-radius: calc(0.5rem - 1px);
  --mdb-card-cap-padding-y: 0.75rem;
  --mdb-card-cap-padding-x: 1.5rem;
  --mdb-card-cap-bg: hsla(0,0%,100%,0);
  --mdb-card-bg: #fff;
  --mdb-card-img-overlay-padding: 1.5rem;
  --mdb-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--mdb-card-height);
  word-wrap: break-word;
  background-color: var(--mdb-card-bg);
  background-clip: border-box;
  border: var(--mdb-card-border-width) solid var(--mdb-card-border-color);
  border-radius: var(--mdb-card-border-radius);
  box-shadow: var(--mdb-card-box-shadow);
}

.mask-custom {
    background: rgba(24, 24, 16, .2);
    border-radius: 2em;
    backdrop-filter: blur(15px);
    border: 2px solid rgba(255, 255, 255, 0.05);
    background-clip: padding-box;
    box-shadow: 10px 10px 10px rgba(46, 54, 68, 0.03);
    color: #fff;
}

.card-body {
      flex: 1 1 auto;
      padding: 1.5rem 1.5rem;
}

.mb-0 {
    margin-bottom: 0!important
}

.ChatBox {
      display: flex;
    align-items: center;
    flex-direction: column;
}



#sendButton {
  margin-left: auto;
  margin-top: 15px;
  --mdb-btn-bg: #fbfbfb;
  --mdb-btn-color: #4f4f4f;
  --mdb-btn-box-shadow: 0 4px 9px -4px #fbfbfb;
  --mdb-btn-hover-bg: #eee;
  --mdb-btn-hover-color: #4f4f4f;
  --mdb-btn-focus-bg: #eee;
  --mdb-btn-focus-color: #4f4f4f;
  --mdb-btn-active-bg: #e2e2e2;
  --mdb-btn-active-color: #4f4f4f;
  --mdb-btn-box-shadow-state: 0 8px 9px -4px hsla(0,0%,98.4%,0.3), 0 4px 18px 0 hsla(0,0%,98.4%,0.2);
}

/*.selectors {
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .btn-link:hover {
          box-shadow: none;
          text-decoration: none;
          background-color: rgb(255, 255, 255, 0.2);
      }
}
*/
.collapseIcon {
  width: 30px;
  height: 30px;
}

.collapseBtn {
  background-color:transparent!important;
  border:0px!important;
  box-shadow:none!important;
  position: absolute;
  z-index: 1;
  top: 0px;
  left:0px;
  padding-left: 0px;
}