/* src/components/AuthStyles.css */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

:root {
  --background-color: #121212;
  --primary-color: #bb86fc;
  --input-background: #1f1f1f;
  --text-color: #ffffff;
  --error-color: #cf6679;
  --border-radius: 10px;
  --transition-duration: 0.3s;
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.auth-box {
  background: var(--input-background);
  padding: 40px;
  border-radius: var(--border-radius);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  text-align: center;
  transition: transform var(--transition-duration), box-shadow var(--transition-duration);
}

.auth-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.7);
}

.auth-container h2 {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 1.5rem;
}

.auth-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-input {
  margin-bottom: 20px;
  width: 100%;
  background-color: var(--input-background);
  border: none;
  padding: 10px;
  border-radius: var(--border-radius);
  color: var(--text-color);
}

.auth-input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.auth-submit-btn {
  background-color: var(--primary-color);
  border: none;
  padding: 10px 20px;
  border-radius: var(--border-radius);
  color: var(--text-color);
  cursor: pointer;
  transition: background-color var(--transition-duration);
}

.auth-submit-btn:hover {
  background-color: rgba(187, 134, 252, 0.8);
}

.error-message {
  margin-top: 20px;
  color: var(--error-color);
  font-size: 0.9rem;
}

.wallet-connect-btn {
  background-color: var(--primary-color);
  border: none;
  padding: 10px 20px;
  border-radius: var(--border-radius);
  color: var(--text-color);
  cursor: pointer;
  transition: background-color var(--transition-duration);
}

.wallet-connect-btn:hover {
  background-color: rgba(187, 134, 252, 0.8);
}
