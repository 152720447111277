.madness-container {
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    background: #171717;
  }
  
  .madness-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../assets/bg.jpeg') center center/cover no-repeat;
    filter: blur(2px) brightness(0.4);
    z-index: 1;
  }
  
  .madness-content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 2rem;
    text-align: center;
  }
  
  .madness-header {
    margin-bottom: 2rem;
  }
  
  .madness-header h1 {
    color: #70ed7c;
    font-size: 3.5rem;
    margin-bottom: 1.5rem;
    text-shadow: 0 0 20px rgba(112, 237, 124, 0.3);
  }
  
  .madness-subtitle {
    color: white;
    font-size: 1.2rem;
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.6;
  }
  
  .twitter-follow {
    margin-top: 2rem;
    color: white;
  }
  
  .twitter-follow p {
    margin-bottom: 0.5rem;
  }
  
  .twitter-icon {
    width: 32px;
    height: 32px;
    transition: transform 0.2s ease;
  }
  
  .twitter-icon:hover {
    transform: scale(1.1);
  }
  
  @media (max-width: 768px) {
    .madness-header h1 {
      font-size: 2.5rem;
    }
    
    .madness-subtitle {
      font-size: 1rem;
      padding: 0 1rem;
    }
  }

  .madness-container {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background: #171717;
}

/* Background image only shows on mobile */
@media (max-width: 768px) {
    .madness-container::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url('../assets/bg.jpeg') center center/cover no-repeat;
      filter: blur(2px) brightness(0.3);
      z-index: 1;
    }

  /* Hide particles on mobile */
  #tsparticles {
    display: none !important;
  }
}

.monad-logo {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 2;
  }
  
  .monad-logo img {
    width: 50px;
    height: auto;
    opacity: 0.8;
    transition: opacity 0.2s ease;
  }
  
  .monad-logo img:hover {
    opacity: 1;
  }
  
  @media (max-width: 768px) {
    .monad-logo img {
      width: 50px;
    }
  }