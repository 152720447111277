/* src/components/PasswordProtect.css */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

:root {
  --background-color: #121212;
  --primary-color: #bb86fc;
  --input-background: #1f1f1f;
  --text-color: #ffffff;
  --error-color: #cf6679;
  --border-radius: 10px;
  --transition-duration: 0.3s;
}


.password-protect-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.waitlist {
  cursor:pointer;
  z-index: 10;
  border-radius: 50px;
  background: rgba(23, 23, 23, 0.9);
  padding: 40px;
  /* border-radius: var(--border-radius); 
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5); */
  text-align: center;
  transition: transform var(--transition-duration), box-shadow var(--transition-duration);
}

/*.password-protect-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.7);
}*/

.password-protect-container h2 {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 1.5rem;
}

.password-protect-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.password-input {
  margin-bottom: 20px;
  width: 100%;
  background-color: var(--input-background);
  border: none;
  padding: 10px;
  border-radius: var(--border-radius);
  color: var(--text-color);
}

.password-input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.password-submit-btn {
  background-color: #fdf9f3ff!important;
  color:#3b3b3b!important;
  border: none;
  padding: 10px 20px;
  border-radius: var(--border-radius);
  color: var(--text-color);
  cursor: pointer;
  transition: background-color var(--transition-duration);
}

.password-submit-btn:hover {
  background-color: rgba(187, 134, 252, 0.8);
}

.error-message {
  margin-top: 20px;
  color: var(--error-color);
  font-size: 0.9rem;
}

#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.password-protect-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.typing-text {
  font-size: 24px;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

/* Reserve space for the form with a minimum height */
.form-container {
  opacity: 1; /* Initially transparent */
  min-height: 150px; /* Adjust the height to reserve space for the form */
}

/* When the form is ready to be shown */
.show-form .form-container {
  visibility: visible; /* Make visible */
  opacity: 1; /* Fade-in the form */
}

/* Styling for the bottom-right email and Twitter icon */
.bottom-right-info {
  z-index: 10;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  opacity: 0;
  animation: fadeIn 2s forwards 4s; /* Adjust timing and duration as needed */
}

.email-link {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

.twitter-icon {
  color: white;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.gw-flex.gw-flex-row.gw-justify-center .gw-text-xs a[href*="getwaitlist.com"] {
    display: none;
}
.gw-self-center {
  display: none;
}

.gw-text-xs  {
  display: none;
}

.password-protect-box {
  opacity:1;
  z-index: 10000;
  width:250px;
}

.twitterIcon {
  position:absolute;
  bottom:0;
  width:100%;
  text-align:center;
  margin-bottom:25px;
  z-index: 10000;
}

.form-control:focus {
  border-color: #000!important;
  box-shadow: inset 0px 0px 0px 1px #000!important;
}

 .password-protect-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: 'Arial', sans-serif;
  }

  /* Form wrapper styling */
  .form-wrapper {
    border-radius: 10px;
    padding: 2rem;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }

  /* Title styling */
  .form-title {
    font-size: 1.8rem;
    color: #fff;
    margin-bottom: 1.5rem;
  }

  /* Input field styling */
  .password-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 1rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    background: #f0f0f0;
    color: #333;
  }

 .password-input::placeholder {
   color: #bbb; /* Change this to the desired color */
   opacity: 1;  /* Ensures full opacity on all browsers */
 }
  /* Button styling */
  .password-submit-btn {
    background-color: #667eea;
    color: white;
    padding: 10px 20px;
    font-size: 1.1rem;
    margin-left:0px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
  }

  .password-submit-btn:hover {
    background-color: #764ba2;
  }