.profile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90vh;
}

.profile-img {
  border: 4px solid #007bff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section-header {
  font-size: 1.25rem;
  color: #007bff;
  margin-bottom: 20px;
}

.wallet-list li {
  margin-bottom: 10px;
}

.wallet-list li .btn {
  margin-left: 15px;
}

.btn:hover {
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3);
}

.shadow-lg {
	background-color: rgba(211, 211, 211, 0.2)!important;
	box-shadow: 0 2px 8px rgba(255, 255, 255, 0.1)!important;
	color: #fff;	
}

.form-label {
	color: gray!important;
}

.addWalletBtn {
	color: #000;
	background-color: rgba(145, 234, 136, 0.5)!important;
}

.unlinkBtn {
	background-color:rgba(0,0,0,0.2)!important;
}

.deleteBtn {
	background-color:rgba(255, 99, 71,0.5t)!important;
}