/* src/ChatRoom.css */
.chat-room {
  max-width: 800px;
  margin: auto;
}

.chat-messages {
  margin-top: 20px;
}

.gif-selector,.emoji-selector-container {
  position: absolute;
  bottom: 60px;
  right: 10px;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  width: 540px;
  max-height: 600px;
  overflow-y: scroll;
}


/* src/components/ChatRoom.css */

.chat-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  width: 40vw;
  overflow-y: scroll;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px;
  border-radius: 10px;
  margin: auto;
  background-color: transparent;
}

.chat-container ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0;
}

.mask-custom {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  margin-bottom: 10px;
}

.form-outline {
  width: 100%;
}

.form-control {
  padding-right: 50px; /* Add some padding to make space for the button */
}

.form-outline .btn-link {
  top: 10px;
  right: 10px;
}

.chatBox {
  max-height: 80vh;
}


.chatCol::-webkit-scrollbar {
  width: 0px; /* Width of the scrollbar */
}

/* Scrollbar Thumb */
.chatCol::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners */
}

/* Scrollbar Thumb on Hover */
.chatCol::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover */
}

/* Scrollbar Track */
.chatCol::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the track */
  border-radius: 10px; /* Rounded corners */
}

.chatRectangle {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;    
  margin:0px!important;
  margin-top:40px!important;
  width:100%;
  justify-content: center;
  margin:auto;

  .form-outline {
    width: 100%;
  }
}

.socialsVerified {
  position: absolute;
  top: -5px;
  left: -5px;
  width: 12px;
  height: 12px;
}

.usernameLink {
  color: rgba(255, 255, 255, 1);
  font-size:12px;
}

.usernameLink:hover {
  color: #fff;
}

.message {
  
  display: inline-block !important;
  min-width: 5% !important;
  max-width: 70% !important;
}

.message p {
    max-width: 100%;
    width: 100%;
}

.sentByOwner .usernameInfo {
    margin-left: auto;
}
.sentByOwner .card-body{
  background: rgba(255, 255, 255, .3)!important;
}

.sentByUser {
  align-self: flex-start;

  .replied {
    margin-left:50px;
  }
}

.message-actions.actionsUser {
  display: none; /* Hide by default */
  position: absolute;
  top: 50%;
  margin-top: -7px;
  flex-direction:column;
  justify-content:space-around;
  transform: translateY(-50%);
  right: -50px;
}


.message-actions.actionsOwner {
  display: none; /* Hide by default */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -60px;
  margin-top:-7px;
  flex-direction:column;
  justify-content:space-around;
}

.messageCard:hover .message-actions {
  display: block; /* Show on hover */
}

.replyIcon {
  width:15px;
  height: 15px;
}

.sendIcon {
  width:15px;
  height: 15px;
  opacity: 0.8;
}

.replyButton {
  background-color: transparent;
  border: none;
  box-shadow: none;
  width:15px;
  height: 15px;
  margin-bottom: 4px;
  margin-top: 4px;
}

.messageCard {
  background-color:transparent!important;
  border: 0px!important;
  box-shadow: none!important; 
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: 8px;
}

.reaction-panel > button {
      background-color: transparent;
      box-shadow: none;
      border: none;
}

.reaction-panel {
    border-radius: 24px;
    background-color: #fff;
    display: inline-flex;
    position: absolute;
}

.sentByOwner .reaction-panel {
        bottom: 0px;
        left: -50%;
}

.sentByUser .reaction-panel {
        bottom: 0px;
        right: -50%;
}

.reactions {
  position: absolute;
  bottom: -10px;
  cursor: pointer;
}

.reply {

  .reactions {
    left: 0;
  }
}

.sender {

  .reactions {
    right: 0;
  }
}


.reaction {
    background-color: #232323;
    border-radius: 50px;
}

.reaction .tooltip {
  visibility: hidden;
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -50px;
  opacity: 0;
  transition: opacity 0.3s;
  text-align:left;
}

.tooltip {
  visibility: visible;
  opacity: 1;
}

.reaction-user {
  display: inline-flex;
  border-radius: 50px;
  padding: 2px;
  font-size: 12px;

  img {
    width: 12px;
    height: 12px;
    margin: auto;
  }
}

.messageWrapper {
    background: rgba(255, 255, 255, 0.05);
    background: color(display-p3 1.000 1.000 1.000 / 0.05);
    border: 1px solid rgba(255, 255, 255, 0.03);
    border: 1px solid color(display-p3 1.000 1.000 1.000 / 0.03);
    border-radius: 8px;
}

.replyToBox {
  max-height: 200px !important;
  width:100%;
  overflow: hidden;
  margin: 0px;
  border: 0px;
}

.btn-close-white {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 5px;
  height: 5px;
  color: #fff;
}

.replied {
  opacity: 0.7;
  margin-bottom:-5px!important;
}

.sentByOwner.replied {
    margin-right:50px!important;
  }

.sentByUser.replied {
    margin-left:50px!important;
  }  

.replied .card-header {
  font-size: 14px;
}

.replied .card-body {
  font-size: 12px;
}

.messageLoader {
      margin: auto;
      margin-bottom: 15px;
      margin-top: 15px;
}

.chatRoomCol {
  margin:auto;
  padding: 0px!important;
}

.chatArea {
  margin-bottom: 0px !important;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  display: inline-flex;
  position: relative;
  flex-direction: row;
  width: 100%;
}

.leftPanel {
    background: rgba(255, 255, 255, 0.01);
    background: color(display-p3 1.000 1.000 1.000 / 0.01);
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.03);
    border-color: color(display-p3 1.000 1.000 1.000 / 0.03);
    border-radius: 0px 0px 8px 8px;

}

#textAreaChatRoom {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 12px;
    background: #252525;
    background: color(display-p3 0.145 0.145 0.145);
    border: 1px solid rgba(255, 255, 255, 0.03);
    border: 1px solid color(display-p3 1.000 1.000 1.000 / 0.03);
    border-radius: 8px;

    .plus {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px;
        gap: 4px;

        width: 24px;
        height: 24px;
        background: rgba(255, 255, 255, 0.1);
        background: color(display-p3 1.000 1.000 1.000 / 0.1);
        border-radius: 32px;
    }

    .form-label {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        /* identical to box height, or 16px */
        display: flex;
        align-items: center;
        font-feature-settings: 'tnum' on, 'lnum' on, 'salt' on;

        color: #FFFFFF;
        color: color(display-p3 1.000 1.000 1.000);

        opacity: 0.5;

        /* Inside auto layout */
        flex: none;
        order: 1;
        flex-grow: 1;

    }

}

.chatCol{
    padding-left: 24px;
    padding-right: 24px;
    height: calc(100vh - [offset]);
    overflow-x: hidden;
}

.messageWrapper.selected {
  background-color: rgba(255, 255, 255, 0.1); /* Example style */
  border: 1px solid #ffd700; /* Example style */
}

.replyingTo {
  width: 100%;
  border-top-left-radius: 10px!important;
  border-top-right-radius: 10px!important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  background-color: #2A2D31!important;
}

.replyingTo #textAreaChatRoom {
    border-radius:0px;
  }

.replyingChatBox {
  border-top-left-radius: 0px!important;
  border-top-right-radius: 0px!important;
}

.chatRoomCol .tokenCard {
  background: transparent;
  border-radius: 0px;
  margin-left: 0px !important;
  border: 0px!important;
  margin-bottom: 0px !important;
}

.tokenHeader {
    border: 1px solid rgba(255, 255, 255, 0.03);
    border: 1px solid color(display-p3 1.000 1.000 1.000 / 0.03);
    border-radius: 8px 8px 0px 0px!important;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 24px;

    h5 {
        font-size: 16px!important;
        font-weight:normal;
    }

    color: #757575;
    font-size: 12px!important;

  .tokenLogo {
    width: 40px!important;
    height: 40px!important;
  }
}

.messagePfp.pfpReply {
    position: absolute;
    bottom: 0;
    right: 0px;
}

.messagePfp.pfpSender {
    position: absolute;
    bottom: 0px;
    left: 0px;
}


.messageWrapper .card-header {
  border:0px!important;
}

.replied .card-body {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  max-height: 120px;
  overflow: hidden;
}


.msg-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chat-area .form-control {
    display: block;
    width: 80%;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #222;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.chat-area .form-control:focus {
    outline: 0;
    box-shadow: inherit;
}


.chat-area .nav-tabs {
    border-bottom: 1px solid #dee2e6;
    align-items: center;
    justify-content: space-between;
    flex-wrap: inherit;
}

.chat-area .nav-tabs .nav-item {
    width: 100%;
}

.chat-area .nav-tabs .nav-link {
    width: 100%;
    color: #180660;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: capitalize;
    margin-top: 5px;
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.chat-area .nav-tabs .nav-item.show .nav-link,
.chat-area .nav-tabs .nav-link.active {
    color: #222;
    background-color: #fff;
    border-color: transparent transparent #000;
}

.chat-area .nav-tabs .nav-link:focus,
.chat-area .nav-tabs .nav-link:hover {
    border-color: transparent transparent #000;
    isolation: isolate;
}

.chat-list h3 {
    color: #222;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: capitalize;
    margin-bottom: 0;
}

.chat-list p {
    color: #343434;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-transform: capitalize;
    margin-bottom: 0;
}

.chat-list a.d-flex {
    margin-bottom: 15px;
    position: relative;
    text-decoration: none;
}

.chat-list .active {
    display: block;
    content: '';
    clear: both;
    position: absolute;
    bottom: 3px;
    left: 34px;
    height: 12px;
    width: 12px;
    background: #00DB75;
    border-radius: 50%;
    border: 2px solid #fff;
}

.msg-head h3 {
    color: #222;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 0;
}

.msg-head p {
    color: #343434;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-transform: capitalize;
    margin-bottom: 0;
}

.msg-head {
    padding: 15px;
    border-bottom: 1px solid #ccc;
}

.moreoption {
    display: flex;
    align-items: center;
    justify-content: end;
}

.moreoption .navbar {
    padding: 0;
}

.moreoption li .nav-link {
    color: #222;
    font-size: 16px;
}

.moreoption .dropdown-toggle::after {
    display: none;
}

.moreoption .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: auto;
    right: 0;
    margin-top: 0.125rem;
}

.msg-body ul {
    overflow: hidden;
}

.msg-body ul li {
    list-style: none;
    margin: 15px 0;
}

.sender {
    display: block;
    width: 70%;
    position: relative;
}


.sender p {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400!important;
    font-size: 12px!important;
    line-height: 130%!important;
    /* or 16px */
    display: flex;
    align-items: center;
    font-feature-settings: 'tnum' on, 'lnum' on, 'salt' on;
    color: #FFFFFF;
    color: color(display-p3 1.000 1.000 1.000);
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    padding: 15px;
    padding-bottom:10px;
    padding-top: 10px;
    display: inline-block;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 0;
}

.sender p b {
    display: block;
    color: #180660;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
}

.reply {
    display: block;
    width: 70%;
    text-align: right;
    position: relative;
    margin-left: auto;
}

.reply p {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400!important;
    font-size: 12px!important;
    line-height: 130%!important;
    /* or 16px */
    display: flex;
    align-items: center;
    font-feature-settings: 'tnum' on, 'lnum' on, 'salt' on;
    color: #FFFFFF;
    color: color(display-p3 1.000 1.000 1.000);
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    padding: 15px;
    padding-bottom:10px;
    padding-top: 10px;
    display: inline-block;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 0;
    background-color: rgba(145, 234, 136, 0.05);
}

.reply p b {
    display: block;
    color: #061061;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
}

.reply:after {
    display: block;
    content: '';
    clear: both;
}

.time {
    display: block;
    color: #000;
    font-size: 12px;
    line-height: 1.5;
    font-weight: 400;
}

li.repaly .time {
    margin-right: 20px;
}

.divider {
    position: relative;
    z-index: 1;
    text-align: center;
}

.msg-body h6 {
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: #222;
    background: #fff;
    display: inline-block;
    padding: 0 5px;
    margin-bottom: 0;
}

.divider:after {
    display: block;
    content: '';
    clear: both;
    position: absolute;
    top: 12px;
    left: 0;
    border-top: 1px solid #EBEBEB;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.send-box {
    padding: 15px;
    border-top: 1px solid #ccc;
}

.send-box form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.send-box .form-control {
    display: block;
    width: 85%;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #222;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.send-box button {
    border: none;
    background: #3867d6;
    padding: 0.375rem 5px;
    color: #fff;
    border-radius: 0.25rem;
    font-size: 14px;
    font-weight: 400;
    width: 24%;
    margin-left: 1%;
}

.send-box button i {
    margin-right: 5px;
}

.send-btns .button-wrapper {
    position: relative;
    width: 125px;
    height: auto;
    text-align: left;
    margin: 0 auto;
    display: block;
    background: #F6F7FA;
    border-radius: 3px;
    padding: 5px 15px;
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
    overflow: hidden;
}

.send-btns .button-wrapper span.label {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    color: #343945;
    font-weight: 400;
    text-transform: capitalize;
    font-size: 13px;
}

#upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.send-btns .attach .form-control {
    display: inline-block;
    width: 120px;
    height: auto;
    padding: 5px 8px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    color: #343945;
    background-color: #F6F7FA;
    background-clip: padding-box;
    border: 1px solid #F6F7FA;
    border-radius: 3px;
    margin-bottom: 5px;
}

.send-btns .button-wrapper span.label img {
    margin-right: 5px;
}

.button-wrapper {
    position: relative;
    width: 100px;
    height: 100px;
    text-align: center;
    margin: 0 auto;
}

button:focus {
    outline: 0;
}

.add-apoint {
    display: inline-block;
    margin-left: 5px;
}

.add-apoint a {
    text-decoration: none;
    background: #F6F7FA;
    border-radius: 8px;
    padding: 8px 8px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
    color: #343945;
}

.add-apoint a svg {
    margin-right: 5px;
}

.chat-icon {
    display: none;
}

.closess i {
    display: none;
}



@media (max-width: 767px) {
    .chat-icon {
        display: block;
        margin-right: 5px;
    }
    .chatlist {
        width: 100%;
    }
    .chatbox {
        width: 100%;
        position: absolute;
        left: 1000px;
        right: 0;
        background: #fff;
        transition: all 0.5s ease;
        border-left: none;
    }
    .showbox {
        left: 0 !important;
        transition: all 0.5s ease;
    }
    .msg-head h3 {
        font-size: 14px;
    }
    .msg-head p {
        font-size: 12px;
    }
    .msg-head .flex-shrink-0 img {
        height: 30px;
    }
    .send-box button {
        width: 28%;
    }
    .send-box .form-control {
        width: 70%;
    }
    .chat-list h3 {
        font-size: 14px;
    }
    .chat-list p {
        font-size: 12px;
    }
    .msg-body ul li.sender p {
        font-size: 13px;
        padding: 8px;
        border-bottom-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .msg-body ul li.repaly p {
        font-size: 13px;
        padding: 8px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }
}

.sentByOwner {
  align-self: flex-end;
  margin-right:50px;
  text-align: right;
}

.sentByUser {
    margin-left: 40px;
}

.sentByOwner .usernameLink {
    margin-left: auto;
}

.chatRoomCol
    {
    width: 99%;
}

.sender .timeSent {
    right: -250px;
}

.reply .timeSent {
    left: -250px;
}

.tokenSocials {
    margin: auto;
    font-size: 16px;
}

.tokenSocials img {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-left:5px;
    margin-right:5px;
}

.rightPanelCol {
    background: transparent;
    height: 90vh;
    width: 100%;
    overflow: hidden;
    padding-left: 24px;
    padding-right: 24px;
}

.blurred {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(3px);
}

.rightPanelTitle {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 0px;
    font-size:20px;
}

.rightPanelCard {
    width: 100%;
    margin-bottom: 24px;
    border-bottom: 2px solid;
    border-bottom-color:rgb(30,32,37);
    background: rgba(255, 255, 255, 0.01);
    background: color(display-p3 1.000 1.000 1.000 / 0.01);
    border: 1px solid rgba(255, 255, 255, 0.03);
    border: 1px solid color(display-p3 1.000 1.000 1.000 / 0.03);
    border-radius: 8px;
    height: 30vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 24px;
}

.donate {
    /* Frame 48095404 */

    margin-left:auto;
    margin-right:auto;

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 8px 8px 16px;
    gap: 8px;

    width: 282px;
    height: 45px;

    border: 1px solid rgba(255, 255, 255, 0.03);
    border: 1px solid color(display-p3 1.000 1.000 1.000 / 0.03);
    border-radius: 48px;

    /* Inside auto layout */
    flex: none;
    order: 3;
    align-self: stretch;
    flex-grow: 0;
    p {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        /* or 18px */
        display: flex;
        align-items: center;
        font-feature-settings: 'tnum' on, 'lnum' on, 'salt' on;

        color: #FFFFFF;
        color: color(display-p3 1.000 1.000 1.000);

        opacity: 0.5;

    }
}
.treasuryButton {
    background: rgba(255, 255, 255, 0.1);
    background: color(display-p3 1.000 1.000 1.000 / 0.1);
    border-radius: 48px;
    padding:12px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    border:none!important;
    padding-top:6px;
    padding-bottom:6px;
    line-height: 150%;
    /* identical to box height, or 21px */
    display: flex;
    align-items: center;
    font-feature-settings: 'tnum' on, 'lnum' on, 'salt' on;

    color: #FFFFFF;
    color: color(display-p3 1.000 1.000 1.000);

    opacity: 0.5;


}

.treasuryAmount {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
    /* identical to box height, or 16px */
    display: flex;
    align-items: center;
    font-feature-settings: 'tnum' on, 'lnum' on, 'salt' on;

    color: #FFFFFF;
    color: color(display-p3 1.000 1.000 1.000);

    .currency {
        opacity: 0.5;
    }

    .contribution {
        opacity: 0.5;
        font-size: 14px!important;
        margin-top:5px;
    }
}

.btn-grad {
   background-image: linear-gradient(to right, #FFEEEE 0%, #DDEFBB  51%, #FFEEEE  100%);
   padding: 15px 45px;
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #eee;
   border-radius: 10px;
 }

 .btn-grad:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }

  .task-progress p {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .progress {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    border-radius: 10px;
  }

  .progress::-webkit-progress-bar {
    background-color: #f0f0f0;
  }

  .progress::-webkit-progress-bar,
  .progress::-webkit-progress-value {
    border-radius: 10px;
  }

  .progress::-moz-progress-bar {
    border-radius: 10px;
  }

  .progress1::-webkit-progress-value {
    background: #18e23c;
  }

  .progress2::-webkit-progress-value {
    background: #ffb453;
  }

  .progress3::-webkit-progress-value {
    background: #F93154;
  }

  .votePanel {
    width: 80%;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .voteButtons button {
    width: 80px;
  }

  #yesBtn{
    background-color:#18e23c;
    color: #fff;
  }
  #noBtn{
    background-color:#F93154;
    color:#fff;
  }
  #abstainBtn{
    background-image: linear-gradient(to right, #FFEEEE 0%, #DDEFBB  51%, #FFEEEE  100%);
  }

  .votingPower {
    margin-left: auto;
    margin-right: auto;
  }

  .comingSoon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    transform: translateX(-50%);
    text-align: center;
    z-index: 1;
  }


  
  .usernameInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 7px;
    height: 16px;


    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    /* identical to box height, or 16px */
    display: flex;
    align-items: center;
    font-feature-settings: 'tnum' on, 'lnum' on, 'salt' on;

    color: #FFFFFF;
    color: color(display-p3 1.000 1.000 1.000);

    opacity: 0.5;

    .usernameLink {
        font-weight: 600;
    }

  }

  .selectors {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
  }

  .treasuryBackground {
    height: 23vh;
        position: absolute;
        opacity: 0.2;
        filter: blur(40px);
  }

  .votingOutcome {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    /* identical to box height, or 36px */
    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on, 'salt' on;

    color: #E6334D;
    color: color(display-p3 0.831 0.267 0.322);

  }

  .votePanel {
    width: 100%;
  }

  .task-progress {
    width: 100%;
  }

  .combined-progress {
    width: 100%;
    height: 5px;
    background-color: #ddd;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
  }

  .progress-segment {
    height: 100%;
  }

  .yes-segment {
    background-color: #4caf50; /* Green color for Yes */
  }

  .no-segment {
    background-color: #f44336; /* Red color for No */
  }


.voteText {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on, 'salt' on;

    color: #fff!important;
}

.voteButton {
    display: flex;
    width:100%!important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 4px;
    color: #fff!important;
    border:none;
    width: 282px;
    height: 40px;
    background: rgba(255, 255, 255, 0.1);
    background: color(display-p3 1.000 1.000 1.000 / 0.1);
    border-radius: 8px;
    flex: none;
    order: 3;
    align-self: stretch;
    flex-grow: 0;

}

.analyticsAmount {
    width: 100px;
    height: 36px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    display: flex;
    align-items: center;
    font-feature-settings: 'tnum' on, 'lnum' on, 'salt' on;
    color: #FFFFFF;
    color: color(display-p3 1.000 1.000 1.000);
    flex: none;
    order: 0;
    flex-grow: 0;

}

.analyticsGrowth {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 150%;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: #00E100;
}

.donate {
    max-width: 100%;
}

@media (max-width: 830px) {
    .chatCol {
      padding-left:10px!important;
      padding-right:0px!important;
    }

    .reply {
      width: 80%;
    }
}

@media (max-width: 500px) {

    .reply {
      width: 90%;
    }

    .tokenHeader {
        font-size: 10px!important;
    }
}
.textarea.tip-mode {
  background-color: #f0f9ff; /* light blue background */
  border: 2px solid #00bfff; /* blue border */
}


.command-input {
  display: block;
  align-items: center;
  gap: 10px;
}

.command-prefix {
  background-color: #5865f2;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.command-input-box {
  background-color: #2c2f33;
  color: white;
  margin: 5px;
  border: 1px solid #5865f2;
  padding: 5px;
  border-radius: 5px;
  outline: none;
  width: auto;
}

.wallet-address-box {
  width: 350px; /* Adjust width as needed */
}

.amount-box {
  width: 100px; /* Adjust width for the amount */
}


.command-input-box {
  background-color: #2c2f33;
  color: white;
  border: 1px solid #5865f2;
  padding: 5px;
  border-radius: 5px;
  outline: none;
  width: auto;
  margin-right: 5px;
}

.comment-box {
  width: 200px; /* Adjust width for the comment input */
}

.wallet-address-box {
    color: gray;
    width: 100px;
    cursor: pointer;
}

/* In your ChatRoom.css or appropriate CSS file */

.admin-message {
  background-color: #2b2b2b; /* A different background color */
  color: #ffd700; /* Gold color for the text */
  padding: 10px;
  border-radius: 5px;
}

.admin-message-card {
  border: 1px solid #ffd700; /* Gold border for the card */
}

.admin-message-content {
  font-weight: bold;
}

.usernameLink.admin-message {
  color: #ffd700; /* Gold color for "Admin" username */
}

.adminMessage .messageWrapper {
        position: absolute;
        text-align: center;
        width: 100% !important;
        background-color: transparent;
        border: 0px;
        padding: 10px;
        font-weight: 800;
        opacity: 0.6;

}

.adminMessage p {
    font-style: italic;
    font-family: 'system-ui';
    font-size: 14px!important;
}


.chainLogo {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -8px;
    left: -8px;
    background-color: slategrey;
    border-radius: 50px;
    padding: 2px;    
    background-color: rgba(255, 255, 255, 0.2);
}