/* src/components/Navbar.css */
.navbar {
  background-color: transparent; /* Light background color */
  height: 7vh;
  margin-left:12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.03);
  border-bottom: 1px solid color(display-p3 1.000 1.000 1.000 / 0.03);
}

.nav-link {
  margin-right: 15px; /* Space between links */
}

.navbar-brand {
  font-size: 1.5rem; /* Larger font size for brand */
  margin-right:50px!important;
}

.btn-primary {
  margin-left: 15px; /* Space between button and links */
}

.nav-link.text {
  color: #fff!important;
  opacity: 0.5;
  padding:0px!important;
  margin-right:25px!important;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}

.nav-link.text.active {
  opacity: 1;
}

.right-nav-menu {
  div a {
    margin-right:25px!important;
    padding:0px!important;
    margin-left:5px;
    color: #fff;
    opacity: 0.5;
  }
}

.dropdown-item {
  color: #000!important;
}

.pfp {
  opacity: 1!important;
}

@media (max-width: 1000px) {
  .navbar-nav {
    display: none!important;
  }

  .logoNav {
    display: none!important;
  }
}

.bellIcon {
  margin-right: 25px;
}

.tokenNotification {
  position: absolute;
  cursor: pointer;
  right:0px;
  margin-top:5px;
  overflow: hidden;
  width: 300px;
  text-align:center;
  font-style: normal;
  font-weight: 600;
  color:#154046;
  font-size: 16px;
  padding: 10px;
  padding-top:15px;
  padding-bottom:15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  background: hsla(114, 70%, 73%, 1);

  background: radial-gradient(circle, hsla(114, 70%, 73%, 1) 0%, hsla(114, 61%, 73%, 1) 100%);

  background: -moz-radial-gradient(circle, hsla(114, 70%, 73%, 1) 0%, hsla(114, 61%, 73%, 1) 100%);

  background: -webkit-radial-gradient(circle, hsla(114, 70%, 73%, 1) 0%, hsla(114, 61%, 73%, 1) 100%);

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#91EA88", endColorstr="#99E491", GradientType=1 );}