/* src/components/WalletConnect.css */
.wallet-connect {
  margin-top: 20px;
}

.wallet-connect .card {
  margin-bottom: 10px;
}

a {
  cursor: pointer;

}

a:hover {
  color:#fff!important;
}

.tokensCol {



  background:transparent!important;
  margin-left: 0;
  border-radius: 0px;
  border-left: 0px;
  width: 90%;
  margin:auto;

  .card-body {
    padding:0px!important;
  }
  margin-bottom: 0 !important;
}

.tokenRow {
  max-width: 550px;
  border: 0;
  width: 100%;
  margin-inline: auto;
  background-color:transparent!important;
}


.card {
  --mdb-card-spacer-y: 1.5rem;
  --mdb-card-spacer-x: 1.5rem;
  --mdb-card-title-spacer-y: 0.5rem;
  --mdb-card-border-width: 1px;
  --mdb-card-border-color: var(--mdb-border-color-translucent);
  --mdb-card-border-radius: 0.5rem;
  --mdb-card-box-shadow: 0 2px 15px -3px rgba(0,0,0,0.07), 0 10px 20px -2px rgba(0,0,0,0.04);
  --mdb-card-inner-border-radius: calc(0.5rem - 1px);
  --mdb-card-cap-padding-y: 0.75rem;
  --mdb-card-cap-padding-x: 1.5rem;
  --mdb-card-cap-bg: hsla(0,0%,100%,0);
  --mdb-card-bg: #fff;
  --mdb-card-img-overlay-padding: 1.5rem;
  --mdb-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--mdb-card-height);
  word-wrap: break-word;
  background-color: var(--mdb-card-bg);
  background-clip: border-box;
  border: var(--mdb-card-border-width) solid var(--mdb-card-border-color);
  border-radius: var(--mdb-card-border-radius);
  box-shadow: var(--mdb-card-box-shadow);
}

.mask-custom {
 background: rgba(24, 24, 16, .2);
    border-radius: 2em;
    backdrop-filter: blur(15px);
    border: 2px solid rgba(255, 255, 255, 0.05);
    background-clip: padding-box;
    box-shadow: 10px 10px 10px rgba(46, 54, 68, 0.03) 
}

.tokenLogo {
  width:40px!important;
  height:40px!important;
  border-radius:20px;
}

.hideIcon {
  width: 12px;
  position: absolute;
  right:5px;
  bottom:5px;
}

.tokenTitle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  height: 24px;

  p {
    font-size:14px!important;
  }


  /* Inside auto layout */
  flex: none;
  order: 0;
  overflow:hidden;

}

.tokenCardContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  height: 40px;
  flex: none;
  order: 1;
  flex-grow: 1;
}

.tokenInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  padding-right:5px;
  margin-top:-10px;
  gap: 12px;
  height: 40px;


  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  position: absolute;
  right: 0;

  .separator {
    font-size:32px;
  }
}


.tokenMetrics {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  height: 12px;


  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

  p {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 2px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 120%;
    /* identical to box height, or 12px */
    letter-spacing: 0.25px;
    text-transform: capitalize;
    font-feature-settings: 'tnum' on, 'lnum' on, 'salt' on;

    color: #757575;
    color: color(display-p3 0.459 0.459 0.459);


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}

.tokenCard {
  /* Frame 48095380 */

  box-sizing: border-box;
  margin-top:16px;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 12px;
  height: 64px;

  background: rgba(255, 255, 255, 0.01);
  background: color(display-p3 1.000 1.000 1.000 / 0.01);
  border: 1px solid rgba(255, 255, 255, 0.03);
  border: 1px solid color(display-p3 1.000 1.000 1.000 / 0.03);
  border-radius: 8px;

  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;

}

.latestMessage {
  /* Frame 48095351 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 4px;

  width: 191px;
  height: 16px;


  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  p {
    height: 16px;
    margin-bottom:0px!important;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    /* identical to box height, or 16px */
    display: flex;
    align-items: center;
    text-align: right;
    font-feature-settings: 'tnum' on, 'lnum' on, 'salt' on;

    color: #FFFFFF;
    color: color(display-p3 1.000 1.000 1.000);

    opacity: 0.5;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

  }
}

.userName {
  margin-right:5px;
}

.timestamp {
  margin-left:5px;
}

.tokenCard {
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.tokenCard:hover {
  background-color: rgba(211, 211, 211, 0.2); /* Light gray background */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Slight shadow for a subtle lift effect */
}


/* Scrollbar Track */
.otherChats::-webkit-scrollbar {
  display:none;
  width: 10px; /* Width of the scrollbar */
}

/* Scrollbar Thumb */
.otherChats::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners */
}

/* Scrollbar Thumb on Hover */
.otherChats::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover */
}

/* Scrollbar Track */
.otherChats::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the track */
  border-radius: 10px; /* Rounded corners */
}

.walletConnectCol {
  padding-left: 0px!important;
  padding-right: 0px!important;
}

.tokenColMenu {
  overflow-x:scroll;
}

.wrapped .tokenColMenu {
  overflow-x:scroll;
}

.tokenColMenu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tokenColMenu {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.wrapped .mostRecent {
  display: none;
}
.wrapped .tokenCard {


  .text-white {
    font-size: 12px;
  }

  overflow: hidden;
}

.tokenColMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  background: rgba(255, 255, 255, 0.01);
  background: color(display-p3 1.000 1.000 1.000 / 0.01);
  border: 1px solid rgba(255, 255, 255, 0.03);
  border: 1px solid color(display-p3 1.000 1.000 1.000 / 0.03);
  border-radius: 32px;
  height: 40px;

  a {

    padding: 4px 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on, 'salt' on;

    color: #FFFFFF;
    color: color(display-p3 1.000 1.000 1.000);

    opacity: 0.5;
    flex: none;
    order: 0;
    flex-grow: 0;

  }
  .active {
    background: rgba(255, 255, 255, 0.1);
    background: color(display-p3 1.000 1.000 1.000 / 0.1);
    border-radius: 32px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    color: #91EA88;
    opacity: 1;
  }
}

.pinnedChats {
    /* Frame 48095376 */

  /* Auto layout */
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 4px;
  height: 18px;
  margin-top:24px;


  a {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on, 'salt' on;

    color: #FFFFFF;
    color: color(display-p3 1.000 1.000 1.000);

    opacity: 0.5;
    flex: none;
    order: 0;
    flex-grow: 0;

  }

  .pushPin {
    margin-right:5px;
  }

  .caretDown {
      margin-left:5px;
    }
}

.firstOne {
  .isTyping {
    color: #91EA88!important;
    opacity: 0.75;
  }
}

.chatIncoming {
  position: absolute;
  width: 20px;
  height: 8px;
  left: 31px;
  top: 10px;
}
.noti {
  position: absolute;
  width: 15px;
  height: 15px;
  left: 31px;
  top: 6px;
}

.separator {
  font-size:32px;
}

.list-tokens {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 90vh;
}

.otherChats {
  overflow-y:scroll;
  height: 100%;
  padding-left: 8px;
}

@media (max-width: 1416px) {
  .latestMessage {
    display: none;
  }

  .tokenInfo {
    flex-direction: row;
  }

  .tokenMetrics {
    flex-direction: column;
    align-items: end;
    justify-content: flex-start;
  }

  .tokenMetricInfo {
    margin:0px!important;
    p {
      margin:0px!important;
    }
  }
}

.rightPanel {
  width: 33%;
}
.leftPanel {
  width: 66%;
}

@media (max-width: 830px) { 

  .tokenSymbol {
    display:none;
  }

  .tokenCardContent {
    display: none;
  }

  .chatIncoming, .noti {
    display: none;
  }

  .tokenImage {
    margin:auto;
  }
}

@media (max-width: 990px) { 
  .mostRecent {
    display: none!important;
  }
}

@media (max-width: 1175px) {
  .tokenColMenu {
    a {
      padding: 3px 3px;
      font-size: 12px;
    }

    overflow-x: scroll;
  }
}


@media (max-width: 880px) {
  .rightPanel {
    display: none;
  }

  .leftPanel {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .tokenColMenu {
    display:none;
  }
}

.initialChats {
  padding-left: 8px;
}

/* CSS for new token highlight */
.newTokenHighlight {
  animation: pulse 1s infinite; /* Subtle animation to grab attention */
  transition: border 0.3s ease;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 10px rgba(255, 215, 0, 0.4);
  }
  100% {
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.8);
  }
}

/* CSS for the "New" badge */
.newBadge {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #91EA88; /* Gold color */
  color: white;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 10px;
  font-size: 12px;
}


/* Highlight the border for new tokens */
.new-token {
  border: 2px solid #91EA88;
}

/* Style the "New" icon */
.new-token-icon {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #91EA88;
  color: black;
  padding: 2px 5px;
  font-size: 10px;
  border-radius: 4px;
}


.hideBtn {
  position: absolute!important;
  bottom:5px!important;
  right:5px!important;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.favBtn {
  position: absolute!important;
  bottom:5px!important;
  right:15px!important;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.favIcon {
  width: 12px;
  position: absolute;
  right:15px;
  bottom:5px;
}